.profile-photo {
  border-radius: 30px;
  box-shadow: 0px 0px 100px 0px rgba(0, 0, 0, 1);
  -webkit-box-shadow: 0px 0px 100px 0px rgba(0, 0, 0, 1);
  -moz-box-shadow: 0px 0px 100px 0px rgba(0, 0, 0, 1);
  border: 3px white solid;
  background-color: #000000;
  translate: 5px 30px;
}

.project-layout {
  background-color: transparent;

}

.projects {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0px;
}

.project-divider {
  transition: 2s;
  width: 90vw;
  font-size: 40px;

}

.project-comments {
  color: white;
  font-size: calc(3vw);
  z-index: -1;
}

.project-videos {
  transition: 0.2s ease-out;
  border: 3px solid #ff0000;
  border-radius: 50px;
  width: 60vw;
  max-width: 400px;
  max-height: 70%;
  justify-content: center;
  padding: 10px;
  margin: 0 auto;
}

.project-videos:hover {
  border: 4px #00ff22 solid;
  box-shadow: 0px 0px 20px 5px rgba(0, 255, 34, 0.75);
}

.hero {
  color: white;
  height: auto;
  display: block;
  position: relative;
  background-color: #000000;
}

.hero-image {
  background-image: url("./assets/background.jpg");
  background-size: cover;
  background-repeat: repeat;
  position: absolute;
  translate: -10px -10px;
  overflow: hidden;
  padding-left: 25%;
  padding-right: 15%;
  padding-top: 12%;
  padding-bottom: 10px;
  filter: blur(2px);
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 105px 30px rgba(0, 0, 0, 0.5) inset;
  -webkit-box-shadow: 0px 0px 105px 30px rgba(0, 0, 0, 0.5) inset;
  -moz-box-shadow: 0px 0px 105px 30px rgba(0, 0, 0, 0.5) inset;
}


.buttons {
  background: #000000;
  border-color: #000000;
  color: white;
  font-size: 2vw;
  padding: 3px;
  padding-inline: 3vw;
  margin: 2vw;
  height: auto;

}

.buttons:hover {
  transform: scale(1);
  transition: 0.2s linear;
  box-shadow: 0px 0px 10px 5px rgba(255, 255, 255, 0.75);
  -webkit-box-shadow: 0px 0px 10px 5px rgba(255, 255, 255, 0.75);
  -moz-box-shadow: 0px 0px 10px 5px rgba(255, 255, 255, 0.75);
  z-index: 1;

}

.buttons:active {
  transform: scale(0.9);
  transition: 0.02s linear;
  box-shadow: 0px 0px 20px 30px rgba(255, 255, 255, 0.75);
  -webkit-box-shadow: 0px 0px 20px 30px rgba(255, 255, 255, 0.75);
  -moz-box-shadow: 0px 0px 20px 30px rgba(255, 255, 255, 0.75);
  z-index: 1;

}

.aboutme {
  text-shadow: 1px 1px 2px black, 0 0 1em black, 0 0 0.2em black;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: calc(3vw);
  text-align: right;
  height: "auto";
  color: white;
  padding-inline: 20px;
  background-color: transparent
}
.footer-base{
  display: flex;
  flex-direction: row;
  
}
.footer-logo{
  z-index: -100;
  transition: 2s ease-in-out;
  margin: 5vw;
  width: 15vw;
  height: auto;
  background-color: white;
  border-radius: 50%;
 }